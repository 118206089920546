import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const QualifiedSWPPPDeveloper = () => (
    <Layout>
        <MetaTags>
            <title>Qualified SWPPP Developer QSD Services - Stormwater Consulting</title>
            <meta name="description" content="Partner with Incompli and gain confidence knowing that our certified QSD professionals will guide you through every step of your stormwater project." />
            <link rel="canonical" href="https://www.incompli.com/qualified-swppp-developer" />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">QSD Services</h1>
        </Hero>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h2>What is a Qualified SWPPP Developer (QSD)?</h2>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                       A Qualified SWPPP Developer (QSD) is responsible for developing a Stormwater Pollution Prevention Plan (SWPPP). The QSD works with you to develop a SWPPP that meets all of the requirements of the California Construction General Permit and includes maps, potential sources of stormwater pollution, and practices that can be used to prevent stormwater pollution on your site. It will also include the following:
                        <ul className="arrow-list">
                                          <li>Erosion and sediment control practices</li>
                                            <li>Good housekeeping practices</li>
                                            <li>Spill prevention and control</li>
                                            <li>Monitoring requirements </li>
                                        </ul>
                       The QSD also identifies procedures the operator will implement to comply with all requirements in the Construction General Permit (CGP). At the LRP request, the QSD or a representative of the QSD will upload the Permit Registration Documents (PRDs) to the state via the SMARTS system. It's important to develop a comprehensive, site-specific, quality SWPPP so that your site is protected. Our QSDs will ensure the SWPPP meets local and federal standards of compliance with the EPA’s expectations regarding environmental impact. Our QSDs go through a rigorous training program to become certified according to the California State Water Board, so you can focus on your project, and leave the stormwater pollution compliance details to us
                    </div>
                </div>
            </div>
        </section>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>What’s the difference between a QSD and QSP services?</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                       The QSD is responsible for the creation and development of the SWPPP, while the QSP (Qualified SWPPP Practitioner) oversees the execution of the SWPPP. And while the QSD can offer QSP services, a QSP might not have the training needed to offer the full breadth of services and skills that a QSD can. Incompli’s qualified SWPPP practitioner and developer services are readily available for your next project.
                   </div>
                </div>
            </div>
        </section>
                        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>Why Partner with Incompli’s Qualified SWPPP Developers</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
A skilled QSD is crucial to ensuring your plan for stormwater compliance meets all local and federal regulations. Partner with Incompli and gain confidence knowing that our team of talented, dedicated QSD professionals will guide you through every step from your project site’s inception through its completion. Once your SWPPP is developed by a QSD, it’s your job to execute on the specified BMPs within the SWPPP to remain in compliance throughout the life of your project. To oversee the execution and compliance, you will need to work with a Qualified SWPPP Practitioner (QSP). If you don’t have a QSP in-house, our QSPs services are available to help you with this next part of the process!                   </div>
                </div>
            </div>
        </section>
                        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h4>An Incompli Qualified SWPPP Developer (QSD) will help you:</h4>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col-lg-11 m-auto text-left">
                        <ul className="arrow-list">
                                          <li>Develop a Stormwater Pollution Prevention Plan (SWPPP) that includes:</li>
                                                <p>--->Permits </p>
                                                <p>--->Maps</p>
                                                <p>--->Potential sources of stormwater pollution</p>
                                                <p>--->Best Management Practices (BMPs) that will be used to prevent stormwater pollution</p>
                                          <li>Identify procedures to maintain Construction General Permit (CGP) compliance</li>
                                          <li>Submit the SWPPP to the state via the SMARTS system</li>
                                        </ul>
`                   Learn more about our Qualified SWPPP Developer and QSP services and <a href="https://www.incompli.com/contact-us">contact us</a> today!
                    </div>
                </div>
            </div>
        </section>
        <Banner id="resources" image="/assets/images/our-vision.jpg">
            <h4>Resources</h4>
            <div>
                <a href="https://www.waterboards.ca.gov/water_issues/programs/stormwater/training.html">California State Waterboards</a>
            </div>
        </Banner>
    </Layout>
);

export default QualifiedSWPPPDeveloper
