import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import IconBox from "../components/IconBox";
import IconBoxFixed from "../components/IconBoxFixed";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const OurCompany = () => (
    <Layout>
        <MetaTags>
            <title>Incompli SWPPP - Stormwater Compliance Experts</title>
            <meta name="description" content="Since 2010 we have helped our clients navigate complicated stormwater compliance requirements - Meet our team and get to know our history and mission statement" />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Incompli: Stormwater Compliance and SWPPP Experts</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}></p>
        </Hero>
        <section id="module" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs nav-justified">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab-5-1">
                                    <h6>Mission</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-2">
                                    <h6>History</h6></a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane show active" id="tab-5-1">
                                 <section id="important">
                                    <p>Our mission at Incompli is to simplify stormwater compliance by leveraging technology and the expertise of our seasoned SWPPP developers and QSP certified inspectors.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-2">
                                <section id="important">
                                    <p>Incompli was founded in May of 2010 to provide a simple and effective solution for construction companies, developers, property owners, and manufacturing companies that are required to navigate complicated stormwater compliance requirements per the general permit. With over 12 years of vast industry experience, our SWPPP and stormwater compliance inspection services provide our customers with greater efficiencies within their projects and peace of mind.</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h2>Company Values</h2>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <IconBoxFixed title="Work and Life Balance">
                        We strive for balance. Whether it’s at work or at home, balance is important to our overall health. Management will do everything in its power to promote balance for our people.
                    </IconBoxFixed>
                    <IconBoxFixed title="Go the Extra Mile">
                        Our customers are our lifeblood. We believe that great customer service means going the extra mile.
                    </IconBoxFixed>
                    <IconBoxFixed title="Always Learning">
                        As one of the top stormwater compliance SWPPP companies, we are constantly improving by proactively learning new skills and learning from our past mistakes.
                    </IconBoxFixed>
                    <IconBoxFixed title="Integrity">
                        We are accountable by making good decisions and doing what's right. Doing business with integrity does not allow for shortcuts.
                    </IconBoxFixed>
                </div>
            </div>
        </section>
         <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h2>Our Stormwater Compliance and SWPPP Process</h2>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <IconBox title="Information Gathering">
                        When you choose Incompli for your stormwater compliance needs, the first step is our Project Coordinator reaching out to introduce themself and gather critical information such as project/site contacts as well as specs so we can provide customized service specific to your project. We will set you up for success and ensure you know your point of contact for any questions that may arise.
                    </IconBox>
                    <IconBox title="Development/Set-Up">
                        After we’ve gathered information for the project/site, we’ll use that information to tailor our products to your needs whether through SWPPP/SWP3 development or scheduling inspection services. Our team of CESSWI or CISEC certified Qualified SWPPP Practitioners (QSPs) will ensure you have everything you need for project success and total stormwater compliance.
                    </IconBox>
                    <IconBox title="Delivery of SWPPP/QSP Services">
                        Once our team has set up everything needed to ensure stormwater compliance for your project/site, we’ll provide you with a customized SWPPP and schedule ongoing monitoring services. A key feature of our monitoring service is the technology we use in creating inspection reports. Our inspectors use specialized, state of the art stormwater compliance software to provide electronic reports with photos and analytics that provide visibility into your project as well as key insights.
                    </IconBox>
                    <IconBox title="Successful Completion of Project/Services">
                        As your project nears completion, close-out procedures can be daunting. Our team of experienced QSDs and stormwater professionals will take steps to ensure your project follows permit-required close-out procedures so you don’t have to worry. We’ll provide a final inspection, file the NOT and also take care of the Annual Report in SMARTS/STEERS. We provide peace of mind as we take care of all of the details so you can focus on other things.
                    </IconBox>
                </div>
            </div>
        </section>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>Why You Can Trust Incompli with Your SWPPP Needs</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                        Our customers experience peace of mind and greater efficiencies within their projects due to our stormwater compliance and SWPPP consulting expertise, vast industry experience, superior products and services, key insights provided through cutting edge technology and software, and unparalleled customer service. We look forward to serving you and your team, and giving you the confidence that your project will be seen through to successful completion.
                    </div>
                </div>
            </div>
        </section>
        <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h2>Management Team</h2>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <IconBox title="Jason Locklin, Founder, President & CEO">
                    <b>Certifications: QSD, Trainer of Record </b>
                    Jason Locklin is the Founder of Incompli. He has over 15 years of experience in the stormwater industry which includes developing stormwater plans, executing site assessments, training internal and external customers, and software product development. Jason holds a degree in Business Management from Pepperdine University as well as several key industry certifications.
                    <a href="http://www.linkedin.com/in/jasonlocklin" IconBox title="Connect with Jason on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="Haven White, Vice President of Operations">
                    <b>Certifications: QSM, MPA, CMP </b>
                    Haven is an operations and program management executive with over 20 years of experience leading in the public, private, and non-profit sectors. She enjoys being a Jane-of-all-trades, solving complex problems, and creating structure and healthy operational practices. Her passion for administration began early in her career while earning her Masters degree in Public Administration, during which she learned the details of running a successful organization and creating smooth processes to increase efficiencies. She has spent her career working in a broad spectrum of organizations, including the federal government, the mortgage banking industry, non-profit organizations, and consulting companies. Her interest in protecting the environment paired with her operational experience meld seamlessly in her current role to ensure smooth business flow and customer success.
                    <a href="https://www.linkedin.com/in/havenwhite/" IconBox title="Connect with Haven on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="Andrew Plunkett, Director of Sales & Marketing">
                    Andrew has been with Incompli since its inception and has been an integral part of the growth and success of our stormwater compliance company. He has a long history of working hands-on in construction throughout his career, giving him the ability to relate with the real world challenges our customers face in the field. Andrew has a strong drive and passion to help his customers succeed, driving the company as a whole towards offering the most value possible to our clients. Because of this, Andrew has built a very loyal and strong client base that continues to entrust Incompli with their business.
                    <a href="https://www.linkedin.com/in/andrew-plunkett-03738177/" IconBox title="Connect with Andrew on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="Patricia Castillo, Environmental Consultant">
                    <b>Certifications: QSP, QISP, QSD, SM-QSD, SM-QSP, CPESC, QSM </b>
                    Patricia oversees plan development and inspection services  for Incompli. Additionally, she conducts on-site inspection and monitoring services, analyzes technical environmental data (including weather events, erosion, soil and water quality), and prepares stormwater plans (including SWPPPs, WPCPs, and WPCDs) to ensure that Incompli clients operate their construction, industrial, and municipal projects in compliance with California and federal regulatory requirements.
                    <a href="https://www.linkedin.com/in/patricia-castillo-41742224/" IconBox title="Connect with Patricia on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="Jesse Cox, Lead Inspector for the Southern California Inspector Team">
                    <b>Certifications: QSP, CESSWI, WPCM, QISP, SM-QSP, OSHA-30 HAZMAT Title-22 </b>
                    Jesse leads our Southern California team of inspectors as they service construction companies, developers, and manufacturing companies throughout the area. He has over 5 years of stormwater experience, and in addition to his current certifications, is currently working on earning the QSD certification to broaden his already extensive knowledge base. In addition to leading our team of QSP-certified inspectors in Southern California, his goal is to increase his influence in the stormwater industry by adding SWPPP development to his plate.
                    <a href="https://www.linkedin.com/in/alex-argiro-cesswi-qsp-sm-qsp-osha-qisp-630b6574/" IconBox title="Connect with Alex on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="John Ritchie, Lead Inspector for the Northern California Inspector Team">
                    <b>Certifications: QSP, QISP, WPCM, OSHA-30, HAZMAT-Title 22 </b>
                    John has over 7 years of experience in the stormwater industry. After earning his Bachelor of Science degree, he went on to earn a QSP and QISP certification. He is currently working on earning the QSD certification to further his knowledge base and provide more opportunities to our clients as a consultant. As lead inspector of the Northern California inspection team, he works hard to keep our clients and their projects in compliance.
                    <a href="https://www.linkedin.com/in/john-ritchie-6b036a6b/" IconBox title="Connect with John on LinkedIn"></a>
                    </IconBox>
                    <IconBox title="Carey Fulton, Accounts Receivable Manager">
                    Carey is the Accounts Receivable Manager at Incompli specializing in accounting, compliance documentation, and billing. He has over 15 years of experience working in finance and 2 years in the stormwater industry. He started his career working in media, particularly finance related to television licensing and the physical products shipped by movie studios before moving to the stormwater industry. He is passionate about ensuring that Incompli’s customers have all of their stormwater needs met and is always available to help. Carey has a bachelor's degree in Fine Arts from California State University at Northridge.
                    </IconBox>
                </div>
            </div>
        </section>
    </Layout>
);

export default OurCompany
