import React from "react";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import MenuLink from "../NavigationMenu/MenuLink/MenuLink";
// import DropDownMenu from "../NavigationMenu/DropdownMenu/DropdownMenu";
// import DropDownLink from "../NavigationMenu/DropdownMenu/DropdownLink";
import { LinkContainer } from "react-router-bootstrap";
import { Dropdown, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

const Header = ({ transparent }) => (
    <Navbar
        expand="lg"
        fixed="top"
        className={`header ${transparent ? " header-transparent" : ""}`}
    >
        <div className="brand-wrapper">
            <LinkContainer to="/">
                <Navbar.Brand className="">
                    <img
                        className="light-logo"
                        src="/assets/images/logo2-lite.png"
                        alt=""
                        style={{ height: 35, width: "auto" }}
                    />
                    <img
                        className="dark-logo"
                        src="/assets/images/logo2.png"
                        alt=""
                        style={{ height: 35, width: "auto" }}
                    />
                </Navbar.Brand>
            </LinkContainer>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto me-auto my-2 my-lg-0">
                <LinkContainer to="/">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <NavDropdown title="SWPPP Services">
                    <LinkContainer to="/swppp-development">
                        <Dropdown.Item>SWPPP Development</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-inspection">
                        <Dropdown.Item>SWPPP Inspection</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-water-sampling">
                        <Dropdown.Item>SWPPP Water Sampling</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-consulting-and-training">
                        <Dropdown.Item>
                            SWPPP Consulting and Training
                        </Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/stormwater-compliance">
                        <Dropdown.Item>Stormwater Compliance</Dropdown.Item>
                    </LinkContainer>
                </NavDropdown>
                <HashLink to="/#whoWeServe" className="nav-link">
                    Who We Serve
                </HashLink>
                <NavDropdown title="Service Areas">
                    <LinkContainer to="/swppp-services">
                        <Dropdown.Item>SWPPP Services</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-consultant-southern-california">
                        <Dropdown.Item>Southern California</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-contractor-northern-california">
                        <Dropdown.Item>Northern California</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-consultant-texas">
                    <Dropdown.Item>Texas</Dropdown.Item>
                </LinkContainer>
                </NavDropdown>
                <NavDropdown title="About">
                    <LinkContainer to="/our-company">
                        <Dropdown.Item>Our Company</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/our-credentials">
                        <Dropdown.Item>Our Credentials</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/our-clients">
                        <Dropdown.Item>Our Clients</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/swppp-faq">
                        <Dropdown.Item>SWPPP FAQ</Dropdown.Item>
                    </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/blog">
                    <Nav.Link>Blog</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact-us">
                    <Nav.Link>Contact Us</Nav.Link>
                </LinkContainer>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export default Header;
