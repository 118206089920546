import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RecentPostsContext from "./utils/RecentPostsContext"
import { baseUrl } from "./config/config.json";
import ScrollRestoration from "./components/ScrollRestoration";
import Home from "./pages/Home";
import About from './pages/About';
import SWPPPServices from './pages/SWPPPServices';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Post from './pages/Post';
import SWPPPDevelopment from './pages/SWPPPDevelopment';
import SWPPPInspection from './pages/SWPPPInspection';
import WaterSampling from './pages/WaterSampling';
import SWPPPConsultingAndTraining from './pages/SWPPPConsultingAndTraining';
import SWPPPMonitoringAndReporting from './pages/SWPPPMonitoringAndReporting';
import QualifiedSWPPPDeveloper from './pages/QualifiedSWPPPDeveloper';
import QualifiedSWPPPPractitioner from './pages/QualifiedSWPPPPractitioner';
import SWPPPServicesCalifornia from './pages/SWPPPServicesCalifornia';
import SWPPPConsultantsSouthernCalifornia from './pages/SWPPPConsultantsSouthernCalifornia';
import SWPPPConsultantNorthernCalifornia from './pages/SWPPPConsultantNorthernCalifornia';
import SWPPPConsultantTexas from './pages/SWPPPConsultantTexas';
import OurCompany from './pages/OurCompany';
import OurCredentials from './pages/OurCredentials';
import OurClients from './pages/OurClients';
import PageNotFound from './pages/PageNotFound';
import SWPPPFAQ from './pages/SWPPPFAQ';
import TagManager from 'react-gtm-module'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import StormwaterCompliance from './pages/StormwaterCompliance';
import ConstructionSWPPPBlog from './pages/ConstructionSWPPPBlog';
import StormwaterPermittingProgramsBlog from './pages/StormwaterPermittingProgramsBlog';
import DroneConstructionSiteMapping from './pages/DroneConstructionSiteMapping';
import SWPPPInspectionsBlog from './pages/SWPPPInspectionsBlog';

const tagManagerArgs = {
    gtmId: 'GTM-56QHBKC'
}

TagManager.initialize(tagManagerArgs)

class App extends React.Component {
  state = {
    posts: []
  }

  componentDidMount() {
    fetch(baseUrl + '/articles?_limit=3&_sort=updated_at:desc')
      .then((res) => res.json())
      .then((posts) => this.setState({
        posts
      }))
      .catch((error) => console.log(error));
  }

  render() {
    const { posts } = this.state;
    return (
      <Router>
        <ScrollRestoration>
          <RecentPostsContext.Provider value={posts}>
            <Switch>
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/about" component={About} /> */}
              <Route exact path="/swppp-services" component={SWPPPServices} />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={Post} />
              <Route exact path="/SWPPP-Development" component={SWPPPDevelopment} />
              <Route exact path="/Stormwater-compliance" component={StormwaterCompliance} />
              <Route exact path="/SWPPP-Inspection" component={SWPPPInspection} />
              <Route exact path="/SWPPP-Water-Sampling" component={WaterSampling} />
              <Route exact path="/SWPPP-Consulting-And-Training" component={SWPPPConsultingAndTraining} />
              <Route exact path="/SWPPP-Monitoring-And-Reporting" component={SWPPPMonitoringAndReporting} />
              <Route exact path="/qualified-swppp-developer" component={QualifiedSWPPPDeveloper} />
              <Route exact path="/qualified-swppp-practitioner" component={QualifiedSWPPPPractitioner} />
              <Route exact path="/swppp-services" component={SWPPPServicesCalifornia} />
              <Redirect strict from="/swppp-services-california" to="/swppp-services" />
              <Route exact path="/swppp-consultant-southern-california" component={SWPPPConsultantsSouthernCalifornia} />
              <Route exact path="/swppp-contractor-northern-california" component={SWPPPConsultantNorthernCalifornia} />
              <Route exact path="/swppp-consultant-texas" component={SWPPPConsultantTexas} />
              <Route exact path="/our-company" component={OurCompany} />
              <Route exact path="/our-credentials" component={OurCredentials} />
              <Route exact path="/our-clients" component={OurClients} />
              <Route exact path="/swppp-faq" component={SWPPPFAQ} />
              <Route exact path="/ConstructionSWPPPBlog" component={ConstructionSWPPPBlog} />
              <Route exact path="/StormwaterPermittingProgramsBlog" component={StormwaterPermittingProgramsBlog} />
              <Route exact path="/SWPPP-Inspections-Blog" component={SWPPPInspectionsBlog} />
              <Route exact path="/drone-mapping" component={DroneConstructionSiteMapping} />
              <Route component={PageNotFound} />
            </Switch>
          </RecentPostsContext.Provider>
        </ScrollRestoration>
      </Router>
    );
  }
}

export default App;
